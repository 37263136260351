import Header from "../../header/Header"
import "./home.css"

export default function Home() {
  return (
    <div className="home">
      <Header/>
        homepage
        </div>
  )
}
