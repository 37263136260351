import NavBar from "./navbar/NavBar"
import Home from "./pages/home/Home";

function App() {
  return (
    <>
    <NavBar/>
    <Home/>
    </>
  );
}

export default App;
